/*
 * @Author       : JiangChao
 * @Date         : 2022-04-12 22:11:38
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-11-05 15:04:36
 * @Description  : 接口
 */
// const VUE_APP_UNIT = process.env.VUE_APP_UNIT;
// const CONFIG = {};

// CONFIG.dev = {
// 	baseURL: 'http://logcloud.sigmawit.com',
// 	// baseURL: 'http://139.196.127.61:15667',
// 	appId: '',
// 	isVConsole: false,
// }
import { getAction, postAction, postBlobAction } from "../utils/request.js";
import { updateBaseUrl } from "../utils/axios.js";

/**
 * 1.获取字典下拉项
 */
const selectApi = (parameter) => getAction("/api/dict/select", parameter);

/**
 * 2.获取注册验证码(返回验证码对应的id)
 */
const verifyCodeRegisterApi = (parameter) =>
  postAction("/api/verifyCode/register", parameter);
/**
 * 删除用户
 */
// const delUserApi = (parameter) => postAction('/api/proxy/delUser', parameter);

/**
 * 3.登录授权
 */
const authApi = (parameter) => postAction("/api/proxy/auth", parameter);

/**
 * 4.获取用户自己的信息
 */
const myselfApi = (parameter) => getAction("/api/user/myself", parameter);

/**
 * 账户注销
 */
// const userCancelApi = (parameter) => postAction('/api/user/cancel', parameter);

/**
 * 5.登录退出
 */
const logOutApi = (parameter) => postAction("/api/proxy/logout", parameter);

/**
 * 6.获取看板统计
 */

const dashboardSummaryApi = (parameter) =>
  getAction("/api/dashboard/summary", parameter);

/**
 * 7.获取Sensor数据列表,地图显示也是这个数据里的定位信息
 */
const dashboardSensorListApi = (parameter) =>
  postAction("/api/dashboard/sensorList", parameter);

/**
 * 8.获取修改/忘记密码验证码
 */

const verifyCodeModifyPwdApi = (parameter) =>
  postAction("/api/verifyCode/modifyPwd", parameter);

/**
 * 9.用户修改密码(登录后)
 */
const userModifyPwdApi = (parameter) =>
  postAction("/api/user/modifyPwd", parameter);

/**
 * 10.获取修改手机号/邮箱验证码
 */

const verifyCodeModifyConcatApi = (parameter) =>
  postAction("/api/verifyCode/modifyConcat", parameter);

/**
 * 11.addByDeviceId
 */
const addByDeviceIdApi = (parameter) =>
  postAction("/api/device/addByDeviceId", parameter);

/**
 * 12.获取用户可以看到的全部Sensor数据列表
 */

const getSensorListApi = (parameter) =>
  postAction("/api/device/sensorList", parameter);
/**
 * 13.获取场景列表
 */
const getSceneListApi = (parameter) => postAction("/api/scene/list", parameter);

/**
 * 14.设备分享
 */
const loggerSharingApi = (parameter) =>
  postAction("/api/device/sharing", parameter);

/**
 * 15.取消设备分享
 */
const cancelLoggerSharingApi = (parameter) =>
  postAction("/api/device/cancelSharing", parameter);

/**
 * 16.取消设备分享
 */
const removeDeviceApi = (parameter) =>
  postAction("/api/device/remove", parameter);

/**
 * 17.修改设备名称
 */
const modifyDetailNameApi = (parameter) =>
  postAction("/api/device/detail/modifyName", parameter);

/**
 * 18.修改设备备注
 */
const modifyDetailRemarkApi = (parameter) =>
  postAction("/api/device/detail/modifyRemark", parameter);

/**
 * 19.获取组织内的设备数据
 */
const orgDeviceListApi = (parameter) =>
  postAction("/api/org/orgDeviceList", parameter);

/**
 * 20.组织详情页面-获取组织详情
 */
const getByOrgIdApi = (parameter) =>
  getAction("/api/org/getByOrgId", parameter);

/**
 * 21.修改用户名
 */
const editNameApi = (parameter) => postAction("/api/user/editName", parameter);

/**
 * 22.修改手机号
 */
const resetMobileNumApi = (parameter) =>
  postAction("/api/user/resetMobileNum", parameter);

/**
 * 23.绑定新邮箱
 */
const resetEmailApi = (parameter) =>
  postAction("/api/user/resetEmail", parameter);

/**
 * 24.设备加入组织-组织详情页添加设备
 */
const deviceToOrgApi = (parameter) =>
  postAction("/api/orgDevice/deviceToOrg", parameter);

/**
 * 26.设备退出组织-组织详情页删除设备
 */
const deviceOutOrgApi = (parameter) =>
  postAction("/api/orgDevice/deviceOutOrg", parameter);

/**
 * 27.设置设备comment
 */
const setAlarmCommentApi = (parameter) =>
  postAction("/api/device/alarm/comment", parameter);

/**
 * 28.获取Sensor数据分页列表,地图显示也是这个数据里的定位信息
 */
const sensorPageListApi = (parameter) =>
  postAction("/api/dashboard/sensorPageList", parameter);

/**
 * 29.保存组织信息,新增组织
 */
const orgSaveApi = (parameter) => postAction("/api/org/save", parameter);

/**
 * 30.获取我的组织（自己创建的以及加入的）
 */
const orgListApi = (parameter) => postAction("/api/org/list", parameter);

/**
 * 31.组织详情页-获取组织内的成员分页数据
 */
const orgUserListApi = (parameter) =>
  postAction("/api/org/orgUserList", parameter);

/**
 * 32.转让组织所有权
 */
const orgTransferApi = (parameter) =>
  postAction("/api/org/transfer", parameter);

/**
 * 33.删除组织信息
 */
const orgDelApi = (parameter) => postAction("/api/org/del", parameter);

/**
 * 34.场景添加设备-获取场景可添加Sensor列表
 */
const sensorCanAddListApi = (sceneId) =>
  getAction(`/api/scene/sensorCanAddList/${sceneId}`);

/**
 * 35.修改组织note
 */
const modifyNoteApi = (parameter) =>
  postAction("/api/org/modifyNote", parameter);

/**
 * 36.忘记密码保存新密码(未登录)
 */
const forgetResetPwdApi = (parameter) =>
  postAction("/api/user/forgetResetPwd", parameter);

/**
 * 37.获取多语言key
 */
const languageKeyApi = (parameter) =>
  postAction("/api/proxy/languageKey", parameter);

/**
 * 38.删除组织成员
 */
const userOrgDeleteApi = (parameter) =>
  postAction("/api/userOrg/delete", parameter);

/**
 * 39.设置告警已读
 */
const alarmReadApi = (parameter) =>
  postAction("/api/data/alarmRead", parameter);

/**
 * 40.删除场景
 */
const sceneDeleteApi = (parameter) =>
  postAction("/api/scene/delete", parameter);

/**
 * 41.地图接口
 */
const getMapDataApi = (parameter) =>
  postAction("/api/baidu/map/test", parameter);

/**
 * 42.获取设备基础信息
 */
const getDetailInfoApi = (parameter) =>
  getAction("/api/device/detail/info", parameter);

/**
 * 43.获取折线图数据
 */
const getDetailLineApi = (parameter) =>
  postAction("/api/device/detail/line", parameter);

/**
 * 44.获取温湿度统计以及summary信息
 */
const getDetailSummaryApi = (parameter) =>
  getAction("/api/device/detail/summary", parameter);

/**
 * 45.保存告警规则
 */
const saveAlarmRulesApi = (parameter) =>
  postAction("/api/device/detail/saveAlarmRules", parameter);

/**
 * 46.获取告警规则
 */
const getAlarmRulesApi = (parameter) =>
  getAction("/api/device/detail/alarmRules", parameter);

/**
 * 47.刷新组织邀请码(返回最新的邀请码)
 */
const refreshInvitationCodeApi = (parameter) =>
  postAction("/api/org/refreshInvitationCode", parameter);

/**
 * 48.开始记录
 */
const startRecordingApi = (parameter) =>
  postAction("/api/device/detail/startRecording", parameter);

/**
 * 49.弃用设备
 */
const discardDeviceApi = (parameter) =>
  postAction("/api/device/detail/discard", parameter);

/**
 * 50.设备导出查看数据showList
 */
const deviceShowListApi = (parameter) =>
  postAction("/api/export/deviceShowList", parameter);

/**
 * 51.设备数据导出
 */
const deviceShowExcelApi = (parameter) =>
  postBlobAction("/api/export/deviceShowExcel", parameter);

/**
 * 52.组织详情页-获取待加入组织的设备
 */
const canAddDeviceListApi = (parameter) =>
  postAction("/api/orgDevice/canAddDeviceList", parameter);

/**
 * 53.组织详情-管理员添加用户至组织
 */
const addToOrgApi = (parameter) =>
  postAction("/api/userOrg/addToOrg", parameter);

/**
 * 54.修改用户在组织内的权限/设置管理员/取消管理员
 */
const editLimitApi = (parameter) =>
  postAction("/api/userOrg/editLimit", parameter);

/**
 * 55.获取场景可以添加的设备列表
 */
const canAddSensorListApi = (parameter) =>
  postAction("/api/scene/canAddSensorList", parameter);

/**
 * 56.新增添加保存场景
 */
const addSceneApi = (parameter) => postAction("/api/scene/add", parameter);

/**
 * 57.获取场景模板列表
 */
const getTemplateListApi = (parameter) =>
  getAction("/api/scene/templateList", parameter);

/**
 * 58.获取场景告警配置信息
 */
const getSceneAlarmConfigApi = (parameter) =>
  getAction("/api/scene/alarmConfig", parameter);

/**
 * 59.场景加入组织
 */
const sceneJoinOrgApi = (parameter) =>
  postAction("/api/scene/joinOrg", parameter);

/**
 * 60.组织详情页-获取可添加的场景数据（自己创建的场景）
 */
const canAddSceneListApi = (parameter) =>
  postAction("/api/org/canAddSceneList", parameter);

/**
 * 61.组织详情页-获取组织内的场景数据
 */
const getOrgSceneListApi = (parameter) =>
  postAction("/api/org/orgSceneList", parameter);

/**
 * 62.组织详情页-添加场景至组织
 */
const addSceneToOrgApi = (parameter) =>
  postAction("/api/org/addSceneToOrg", parameter);

/**
 * 63.用户加入组织(用户中心页面加入组织)
 */
const userJoinOrgApi = (parameter) =>
  postAction("/api/userOrg/joinOrg", parameter);

/**
 * 64.用户中心显示的组织信息
 */
const userCenterListApi = (parameter) =>
  getAction("/api/org/userCenterList", parameter);

/**
 * 65.获取告警详情列表
 */
const getAlarmListApi = (parameter) =>
  postAction("/api/device/detail/alarmList", parameter);

/**
 * 66.移交设备
 */
const deviceTransferApi = (parameter) =>
  postAction("/api/device/detail/transfer", parameter);

/**
 * 67.设备加入场景(设备详情页面)
 */
const deviceToSceneApi = (parameter) =>
  postAction("/api/device/detail/deviceToScene", parameter);

/**
 * 68.设备加入组织(设备详情页面)
 */
const deviceDetailToOrgApi = (parameter) =>
  postAction("/api/device/detail/deviceToOrg", parameter);

/**
 * 69.停止记录
 */
const stopRecordingApi = (parameter) =>
  postAction("/api/device/detail/stopRecording", parameter);

/**
 * 70.详情页删除设备
 */
const removeDetailDeviceApi = (parameter) =>
  postAction("/api/device/detail/remove", parameter);

/**
 * 71.获取分享的用户信息
 */
const shareUsersApi = (parameter) =>
  getAction("/api/device/shareUsers", parameter);

/**
 * 72.设置告警已读
 */
const setAlarmReadApi = (parameter) =>
  postAction("/api/device/alarm/alarmRead", parameter);

/**
 * 73.场景-获取场景下已添加的Sensor分页列表
 */
const addedSensorListApi = (parameter) =>
  postAction("/api/scene/addedSensorList", parameter);

/**
 * 74.场景页面-场景页面删除设备信息
 */
const sceneDelDeviceApi = (parameter) =>
  postAction("/api/scene/delDevice", parameter);

/**
 * 75.获取看板告警统计
 */
const alarmTotalApi = (parameter) =>
  postAction("/api/dashboard/alarmTotal", parameter);

/**
 * 76.获取pdf查看地址
 */
const getPdfApi = (parameter) =>
  postAction("/api/device/detail/getPdf", parameter);

/**
 * 77.场景页面保存告警配置
 */
const sceneSaveApi = (parameter) =>
  postAction("/api/device/alarm/config/sceneSave", parameter);

/**
 * 78.用户主动退出组织
 */
const userQuitApi = (parameter) =>
  postAction("/api/userOrg/userQuit", parameter);

/**
 * 79.场景-添加设备信息
 */
const sceneAddDeviceApi = (parameter) =>
  postAction("/api/scene/addDevice", parameter);

/**
 * 80.用户中心修改组织备注
 */
const userCenterRemarkApi = (parameter) =>
  postAction("/api/org/userCenterRemark", parameter);

/**
 * 81.修改组织用户备注
 */
const userRemarkApi = (parameter) =>
  postAction("/api/org/userRemark", parameter);

/**
 * 82.获取设备可以加入的场景列表
 */
const sceneDeviceJoinListApi = (parameter) =>
  postAction("/api/scene/deviceJoinList", parameter);

/**
 * 83.获取设备可加入组织列表
 */
const orgDeviceJoinListApi = (parameter) =>
  postAction("/api/org/deviceJoinList", parameter);

/**
 * 84.场景-获取场景信息(不包含场景下的设备列表)
 */
const getSceneInfoApi = (parameter) => getAction("/api/scene/info", parameter);

/**
 * 85.修改场景备注
 */
const modifySceneRemarkApi = (parameter) =>
  postAction("/api/scene/modifyRemark", parameter);

/**
 * 86.注册
 */
const registerApi = (parameter) => postAction("/api/proxy/register", parameter);

/**
 * 87.设置时区
 */
const setTimezoneApi = (parameter) =>
  postAction("/api/common/timezone", parameter);

/**
 * 88.获取应用规则设备列表
 */
const getDeviceListApi = (parameter) =>
  postAction("/api/alarm/setting/deviceList", parameter);

/**
 * 89.设备应用规则配
 */
const applyAlarmSettingApi = (parameter) =>
  postAction("/api/alarm/setting/apply", parameter);

/**
 * 90.删除规则
 */
const deleteAlarmSettingApi = (parameter) =>
  postAction("/api/alarm/setting/del", parameter);

/**
 * 91.获取可以加入运单的设备（运单详情页也是这个接口）
 */
const getShipmentCanAddDeviceListApi = (parameter) =>
  postAction("/api/shipment/canAddDeviceList", parameter);

/**
 * 92.新增运单
 */
const addShipmentApi = (parameter) =>
  postAction("/api/shipment/save", parameter);

/**
 * 93.获取告警规则模板列表
 */
const getShipmentTemplateListApi = (parameter) =>
  getAction("/api/shipment/templateList", parameter);

/**
 * 94.运单告警列表
 */
const getShipmentAlarmPageListApi = (parameter) =>
  postAction("/api/dashboard/shipmentAlarmPageList", parameter);

/**
 * 95.获取运单列表
 */
const getShipmentListApi = (parameter) =>
  postAction("/api/shipment/list", parameter);

/**
 * 96.删除运单
 */
const deleteShipmentApi = (parameter) =>
  postAction("/api/shipment/del", parameter);

/**
 * 97.运单开始
 */
const shipmentStartApi = (parameter) =>
  postAction("/api/shipment/start", parameter);

/**
 * 98.运单结束
 */
const shipmentEndApi = (parameter) =>
  postAction("/api/shipment/end", parameter);

/**
 * 99.获取运单详情信息
 */
const getByShipmentIdApi = (parameter) =>
  getAction("/api/shipment/detail/getByShipmentId", parameter);

/**
 * 100.运单告警规则
 */
const getShipmentAlarmConfigApi = (parameter) =>
  getAction("/api/shipment/detail/alarmConfig", parameter);

/**
 * 101.获取运单状态分组数据列表
 */
const getGroupByStatusListApi = (parameter) =>
  getAction("/api/shipment/groupByStatusList", parameter);

/**
 * 102.获取运单归属组织
 */
const getShipmentOrgListApi = (parameter) =>
  postAction("/api/shipment/detail/orgList", parameter);

/**
 * 103.获取运单告警列表
 */
const getShipmentDetailAlarmListApi = (parameter) =>
  postAction("/api/shipment/detail/alarmList", parameter);

/**
 * 104.设置运单告警checked
 */
const shipmentDetailAlarmCheckedApi = (parameter) =>
  postAction("/api/shipment/detail/alarmChecked", parameter);

/**
 * 105.设置运单告警comment
 */
const shipmentDetailAlarmAddCommentApi = (parameter) =>
  postAction("/api/shipment/detail/alarmAddComment", parameter);

/**
 * 106.获取运单设备折线数据(折线选择日期时使用)
 */
const getShipmentDetailLineDataApi = (parameter) =>
  postAction("/api/shipment/detail/lineData", parameter);

/**
 * 107.获取运单设备数据相关信息
 */
const getShipmentDetailDeviceDataApi = (parameter) =>
  postAction("/api/shipment/detail/deviceData", parameter);

/**
 * 108.运单统计总览
 */
const getDashboardShipmentTotalApi = (parameter) =>
  postAction("/api/dashboard/shipmentTotal", parameter);

/**
 * 109.获取可加入的运单
 */
const getCanAddShipmentListApi = (parameter) =>
  getAction("/api/device/detail/canAddShipmentList", parameter);

/**
 * 110.获取可加入的运单
 */
const addDeviceToShipmentListApi = (parameter) =>
  postAction("/api/device/detail/addToShipmentList", parameter);

/**
 * 111.获取选择设备后最小上传时间间隔
 */
const getDeviceMinUploadIntervalApi = (parameter) =>
  postAction("/api/shipment/getDeviceMinUploadInterval", parameter);

/**
 * 112.修改运单组织
 */
const updateShipmentOrgApi = (parameter) =>
  postAction("/api/shipment/detail/updateShipmentOrg", parameter);





export {
  updateShipmentOrgApi,
  getDeviceMinUploadIntervalApi,
  addDeviceToShipmentListApi,
  getCanAddShipmentListApi,
  getDashboardShipmentTotalApi,
  getShipmentDetailDeviceDataApi,
  getShipmentDetailLineDataApi,
  shipmentDetailAlarmAddCommentApi,
  shipmentDetailAlarmCheckedApi,
  getShipmentDetailAlarmListApi,
  getShipmentOrgListApi,
  getGroupByStatusListApi,
  getShipmentAlarmConfigApi,
  getByShipmentIdApi,
  shipmentEndApi,
  shipmentStartApi,
  deleteShipmentApi,
  getShipmentListApi,
  getShipmentAlarmPageListApi,
  getShipmentTemplateListApi,
  addShipmentApi,
  getShipmentCanAddDeviceListApi,
  deleteAlarmSettingApi,
  applyAlarmSettingApi,
  getDeviceListApi,
  setTimezoneApi,
  modifySceneRemarkApi,
  getSceneInfoApi,
  orgDeviceJoinListApi,
  sceneDeviceJoinListApi,
  userRemarkApi,
  userCenterRemarkApi,
  sceneAddDeviceApi,
  userQuitApi,
  sceneSaveApi,
  getPdfApi,
  alarmTotalApi,
  sceneDelDeviceApi,
  addedSensorListApi,
  sensorPageListApi,
  setAlarmCommentApi,
  setAlarmReadApi,
  shareUsersApi,
  removeDetailDeviceApi,
  stopRecordingApi,
  deviceDetailToOrgApi,
  deviceToSceneApi,
  deviceTransferApi,
  getAlarmListApi,
  userCenterListApi,
  userJoinOrgApi,
  addSceneToOrgApi,
  getOrgSceneListApi,
  canAddSceneListApi,
  sceneJoinOrgApi,
  getSceneAlarmConfigApi,
  getTemplateListApi,
  addSceneApi,
  canAddSensorListApi,
  editLimitApi,
  addToOrgApi,
  canAddDeviceListApi,
  deviceShowExcelApi,
  deviceShowListApi,
  discardDeviceApi,
  startRecordingApi,
  refreshInvitationCodeApi,
  getAlarmRulesApi,
  saveAlarmRulesApi,
  getDetailSummaryApi,
  getDetailLineApi,
  getDetailInfoApi,
  getMapDataApi,
  sceneDeleteApi,
  alarmReadApi,
  userOrgDeleteApi,
  languageKeyApi,
  forgetResetPwdApi,
  modifyNoteApi,
  sensorCanAddListApi,
  orgDelApi,
  orgTransferApi,
  orgUserListApi,
  orgListApi,
  orgSaveApi,
  deviceOutOrgApi,
  deviceToOrgApi,
  resetEmailApi,
  resetMobileNumApi,
  editNameApi,
  getByOrgIdApi,
  orgDeviceListApi,
  modifyDetailRemarkApi,
  modifyDetailNameApi,
  removeDeviceApi,
  cancelLoggerSharingApi,
  loggerSharingApi,
  getSceneListApi,
  getSensorListApi,
  addByDeviceIdApi,
  verifyCodeModifyConcatApi,
  userModifyPwdApi,
  verifyCodeModifyPwdApi,
  dashboardSensorListApi,
  dashboardSummaryApi,
  logOutApi,
  myselfApi,
  authApi,
  verifyCodeRegisterApi,
  selectApi,
  registerApi,
  updateBaseUrl,
};
